import styled from '@emotion/styled'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Filters } from './Filters'
import { Props as RoomProps, Room } from './Room'
import { RoomMobile } from './RoomMobile'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual="desktopSmall">
          <Filters rooms={rooms} />
        </Media>

        <Media lessThan="desktopSmall">
          {uniqBy(rooms, 'title').map((item, index) => (
            <RoomMobile key={index} {...item} />
          ))}
        </Media>

        <Media greaterThanOrEqual="desktopSmall">
          {uniqBy(rooms, 'title').map((item, index) => (
            <Room key={index} {...item} />
          ))}
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section``
