import styled from '@emotion/styled'
import { BannerGallery } from 'app/components/BannerGallery'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useEffect } from 'react'
import { scroller } from 'react-scroll'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

import { Info } from './Info'

export interface Props {
  description?: string
  images?: ImageProps[]
  label?: string
  languageCode: string
  pax?: string
  services?: string[]
  size?: string
  title?: string
  reservationURL?: string
}

export const Room = memo(function Room({
  description,
  images,
  label,
  languageCode,
  pax,
  services,
  size,
  title,
  reservationURL,
}: Props) {
  if (!title) {
    return null
  }

  const section = title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParam = new URLSearchParams(window.location.search).get(
        'room',
      )

      if (searchParam) {
        scroller.scrollTo(`room-${searchParam}`, {
          offset: -150,
        })
      }
    }

    return () => {
      scroller.unmount()
    }
  }, [])

  return (
    <Container id={`room-${section}`}>
      <Wrapper dial={4} row stretch wrap>
        <LeftSide dial={4}>
          <FadeInUp>
            <Title>
              <InnerTitle>{title}</InnerTitle>
            </Title>
          </FadeInUp>

          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}

          {services ? (
            <Services>
              {services.map((item: any, index) => (
                <Service key={index}>{item.label}</Service>
              ))}
            </Services>
          ) : null}
        </LeftSide>

        <RightSide>
          <Info pax={pax} size={size} />

          {label ? (
            <Label>
              <ParallaxProvider>
                <Parallax translateY={['0px', '-200px']}>
                  <Inner>{label}</Inner>
                </Parallax>
              </ParallaxProvider>
            </Label>
          ) : null}
        </RightSide>
      </Wrapper>

      {images && images.length > 0 ? (
        <BannerGallery
          buttonURL={reservationURL}
          languageCode={languageCode}
          images={images}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 9.375rem;
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled(FlexBox)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding: 4.5rem 8.333vw;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 3.875rem;
  white-space: nowrap;
  &:after {
    content: '';
    display: inline-block;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin-left: 0.9375rem;
    transform: translateY(-0.6875rem);
  }
`

const InnerTitle = styled.span`
  display: inline;
  white-space: normal;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 2.5rem;
`

const Services = styled.div`
  margin-top: 2.5rem;
`

const Service = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 1.25rem;
  padding-left: 5.25rem;
  position: relative;
  &:before {
    content: '';
    width: 3.75rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 1.0625rem;
    left: 0;
  }
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 7.5rem 8.333vw;
  position: relative;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 300;
  line-height: 6.8125rem;
  position: absolute;
  top: 7.5rem;
  right: 8.333vw;
  z-index: 4;
  white-space: nowrap;
  writing-mode: vertical-rl;
  transform: scaleX(-1) scaleY(-1);
`

const Inner = styled.div``
