import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(rooms, 'title').map((item, index) => {
        if (!item.title) {
          return undefined
        }

        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-159}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {item.title}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  border-top: 0.0625rem solid ${rgba(theme.colors.variants.neutralDark5, 0.05)};
  padding: 1.0625rem 2.25rem;
  position: absolute;
  top: -4.0625rem;
  left: 0;
  z-index: 4;
  &.fixed {
    position: fixed;
    transform: translateY(9.625rem);
    transition: 0.3s 0.3s;
  }
`

const Filter = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark6};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.875rem;
  opacity: 0.8;
  position: relative;
  transition: 0.2s ease-in-out;
  &.active,
  &:hover {
    opacity: 1;
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.variants.neutralDark5};
    width: 3.75rem;
    height: 0.0625rem;
    opacity: 0.1;
    margin: 0 3.125rem;
  }

  @media (max-width: 1439px) {
    &:after {
      width: 2.5rem;
      margin: 0 1.875rem;
    }
  }
`
