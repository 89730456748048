import styled from '@emotion/styled'
import { Mq, User } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  pax?: string
  size?: string
}

export const Info = memo(function Info({ pax, size }: Props) {
  if (!pax || !size) {
    return null
  }

  return (
    <Container>
      {size ? (
        <Item dial={4} row>
          <Mq />
          {size}
        </Item>
      ) : null}

      {pax ? (
        <Item dial={4} row>
          <User />
          {pax}
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-top: 1.875rem;
    padding: 1.4375rem 1.5rem;
  }
`

const Item = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 4.625rem;
  &:first-of-type {
    margin-top: 0;
  }

  svg {
    width: 5.875rem;
    height: auto;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark5};
    margin-right: 2.8125rem;
  }

  @media (max-width: 1199px) {
    font-size: 0.875rem;
    margin: 0 1.25rem;
    svg {
      width: auto;
      height: 1.625rem;
      margin-right: 0.75rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
      stroke-width: 2;
    }
  }
`
