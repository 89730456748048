import styled from '@emotion/styled'
import { BannerGallery } from 'app/components/BannerGallery'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect } from 'react'
import { scroller } from 'react-scroll'

import { Info } from './Info'

export interface Props {
  description?: string
  images?: ImageProps[]
  label?: string
  languageCode: string
  pax?: string
  services?: string[]
  size?: string
  title?: string
  reservationURL?: string
}

export const RoomMobile = memo(function RoomMobile({
  description,
  images,
  label,
  languageCode,
  pax,
  services,
  size,
  title,
  reservationURL,
}: Props) {
  if (!title) {
    return null
  }

  const section = title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParam = new URLSearchParams(window.location.search).get(
        'room',
      )

      if (searchParam) {
        scroller.scrollTo(`room-${searchParam}`, {
          offset: -150,
        })
      }
    }

    return () => {
      scroller.unmount()
    }
  }, [])

  return (
    <Container>
      <FadeInUp>
        <Title>
          <InnerTitle>{title}</InnerTitle>
        </Title>
      </FadeInUp>

      <Info pax={pax} size={size} />

      <Wrapper>
        {images && images.length > 0 ? (
          <>
            <BannerGallery languageCode={languageCode} images={images} />

            {label ? <Label>{label}</Label> : null}
          </>
        ) : null}
      </Wrapper>

      {services ? (
        <Services>
          {services.map((item: any, index) => (
            <Service dial={5} key={index} row>
              {item.label}
            </Service>
          ))}
        </Services>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {reservationURL ? (
        <CTA
          label={useVocabularyData('book-now', languageCode || 'it-IT')}
          URL={reservationURL}
          rel="noopener"
          target="_blank"
          variant="full"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  margin-top: 7.5rem;
  text-align: center;
  &:first-of-type {
    margin-top: 6.25rem;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 2.8125rem;
  padding: 0 1.5rem;
  &:after {
    content: '';
    display: inline-block;
    width: 1.875rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin-left: 0.9375rem;
    transform: translateY(-0.5rem);
  }
`

const InnerTitle = styled.span`
  display: inline;
  white-space: normal;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 2.125rem;
  position: absolute;
  top: calc(100% - 6.25rem);
  right: 1.5rem;
  left: 1.5rem;
  z-index: 4;
`

const Services = styled.div`
  margin-top: 2.5rem;
  padding: 0 1.5rem;
`

const Service = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 1.25rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    flex: 1;
  }
  &:before {
    margin-right: 8.411vw;
  }
  &:after {
    margin-left: 8.411vw;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 2.125rem;
  margin-top: 1.875rem;
  padding: 0 1.5rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
